<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mobile" cols="12" class="w-100">
        <div class="d-flex align-stretch background--grey w-100 pa-2">
          <div class="w-100 mr-2 pa-2" :class="{ 'background--white': selected === 'Invoices' }"
            @click="selected = 'Invoices'"
          >
            Invoices
          </div>
          <div class="w-100 mr-2 pa-2"
            :class="{ 'background--white': selected === 'Credit Notes' }"
            @click="selected = 'Credit Notes'"
          >
            Credit Notes
          </div>
        </div>
        <div class="d-flex align-stretch background--grey w-100 pa-2">
          <v-btn color="accent" class="black--text" block
            @click="emailStatement()"
            :loading="isEmailing"
          >
            Email Statement
          </v-btn>
        </div>
      </v-col>
      <v-col v-else cols="12" class="d-flex flex-row align-center justify-center">
        <v-btn :color="selected === 'Invoices' ? 'primary' : ''"
          @click="selected = 'Invoices'"
          class="mr-5"
        >
          Invoices
        </v-btn>
        <v-btn :color="selected === 'Credit Notes' ? 'primary' : ''"
          @click="selected = 'Credit Notes'"
          class="mr-5"
        >
          Credit Notes
        </v-btn>
        <div class="flex flex-row align-center">
          <v-select
            v-model="selectedStatementMonth"
            :items="statementMonths"
            dense
            class="d-inline-block mr-5 mt-6"
            return-object
            :item-text="item => `${item.year} - ${item.monthName}`" label="Statement Month"
          />
          <v-btn color="accent" class="black--text mb-2"
            @click="downloadStatement()"
            :loading="isDownloading"
          >
            Download Statement
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <ListFinanceDocuments v-if="selected === 'Invoices'" arType="OInv" valueProp="debit" />
        <ListFinanceDocuments v-if="selected === 'Credit Notes'" arType="Crn" valueProp="credit" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import axios from 'axios';
import ListFinanceDocuments from '../components/ListFinanceDocuments.vue';

export default {
  name: 'FinanceHome',
  components: { ListFinanceDocuments },
  data() {
    return {
      selected: 'Invoices',
      isDownloading: false,
      isEmailing: false,
      selectedStatementMonth: {
        year: 2024,
        monthName: 'November',
        month: 11,
      },
    };
  },
  computed: {
    statementMonths() {
      return [{
        year: 2024,
        monthName: 'June',
        month: 6,
      },
      {
        year: 2024,
        monthName: 'July',
        month: 7,
      },
      {
        year: 2024,
        monthName: 'August',
        month: 8,
      },
      {
        year: 2024,
        monthName: 'September',
        month: 9,
      },
      {
        year: 2024,
        monthName: 'October',
        month: 10,
      },
      {
        year: 2024,
        monthName: 'November',
        month: 11,
      }];
    },
  },
  async mounted() {
    this.SET_title('Accounts');
    this.SET_expandedSearch(false);
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async emailStatement() {
      this.$log.debug('email statement');
      try {
        this.isEmailing = true;
        const url = '/api/finance/email-statement';
        const token = this.$auth.getToken();
        const { data } = await axios({
          url,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        this.$log.debug('email', data);
        this.$root.$emit('toast:notify', 'Email sent');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'There was an error emailing the statement, please try again later');
      } finally {
        this.isEmailing = false;
      }
    },
    async downloadStatement() {
      this.$log.debug('download statement');
      try {
        this.isDownloading = true;
        const { year, month } = this.selectedStatementMonth;

        const url = `api/finance/monthly-statement/${year}/${month}`;
        this.$log.debug('url', url);
        const token = this.$auth.getToken();
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        this.$log.debug('response', response);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Statement.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'There was an error downloading the Statement, please try again later');
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
</script>
