<template>
  <v-row>
    <v-col v-if="$vuetify.breakpoint.mobile" cols="12">
     <ProgressLinearTimeout v-if="isLoading" />
      <v-list v-else>
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="download(item)"
            :loading="item.isDownloading"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.reference }}</v-list-item-title>
              <v-list-item-subtitle style="text-wrap: normal;">
                Amount: R {{ formatCurrency(item.amount) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle style="text-wrap: normal;">
                Outstanding: R {{ formatCurrency(item.outstanding) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ formatDate(item.txDate) }}
              <v-btn
                x-small
                class="secondary--text"
                color="primary"
                @click="email(item)"
                :loading="item.isEmailing">
                Email
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col v-else cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="isLoading"
        :search="search"
        item-key="reference"
        :disable-sort="$vuetify.breakpoint.mobile"
        sort-by="txDate"
        sort-desc
      >
        <template v-slot:item.txDate="{ item }">
          {{ formatDate(item.txDate) }}
        </template>
        <template v-slot:item.amount="{ item }">
          <div class="text-right">
            R {{ formatCurrency(item.amount) }}
          </div>
        </template>
        <template v-slot:item.outstanding="{ item }">
          <div class="text-right">
            R {{ formatCurrency(item.outstanding) }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn-toggle>
            <v-btn
              small
              @click="download(item)"
              :loading="item.isDownloading"
              v-if="!$vuetify.breakpoint.mobile"
              class="secondary--text mr-2"
              color="#EE1C25"
            >
              View
            </v-btn>
            <v-btn
              small
              class="secondary--text"
              color="primary"
              @click="email(item)"
              :loading="item.isEmailing">
              Email
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiFileDocumentOutline, mdiEmail, mdiMagnify } from '@mdi/js';
import { sortBy } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import axios from 'axios';

export default {
  name: 'ListFinanceDocuments',
  props: ['arType', 'valueProp'],
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
  },
  data() {
    return {
      mdiFileDocumentOutline,
      mdiEmail,
      mdiMagnify,
      emailing: [],
      dataItems: [],
      search: '',
      downloading: [],
    };
  },
  watch: {
    arType: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  computed: {
    headers() {
      return [{
        text: 'Date',
        value: 'txDate',
        class: 'text-center',
      }, {
        text: 'Name',
        value: 'name',
        class: 'text-center',
      }, {
        text: 'Reference',
        value: 'reference',
        class: 'text-center',
      }, {
        text: 'Amount',
        value: 'amount',
        class: 'text-end',
      }, {
        text: 'Outstanding',
        value: 'outstanding',
        class: 'text-end',
      }, {
        text: '',
        value: 'actions',
        class: 'text-end',
      }];
    },
    items() {
      const {
        dataItems, downloading, emailing, valueProp,
      } = this;
      const result = dataItems.map((d) => {
        const item = { ...d };
        item.amount = item[valueProp];
        item.isDownloading = downloading.includes(item.reference);
        item.isEmailing = emailing.includes(item.reference);
        return item;
      });
      return sortBy(result, (r) => r.txDate).reverse();
    },
  },
  methods: {
    ...mapActions('finance', ['LOAD_accountsReceivable']),
    async email(item) {
      this.$log.debug('email', item);
      const { arType } = this;
      const { reference } = item;
      try {
        this.emailing.push(reference);
        const url = `/api/finance/email/${arType}/${reference}`;
        const token = this.$auth.getToken();
        const { data } = await axios({
          url,
          method: 'POST',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        this.$log.debug('email', data);
        this.$root.$emit('toast:notify', 'Email sent');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', `There was an error emailing the ${item.reference}, please try again later`);
      } finally {
        this.emailing.pop(reference);
      }
    },
    async download(item) {
      this.$log.debug('download', item);
      const { arType } = this;
      const { reference } = item;
      try {
        this.downloading.push(reference);
        let url = `api/finance/invoiceDownload/${reference}`;
        let downloadType = 'Invoice';
        if (arType === 'Crn') {
          url = `api/finance/creditNoteDownload/${reference}`;
          downloadType = 'Credit Note';
        }
        this.$log.debug('url', url);
        const token = this.$auth.getToken();
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        this.$log.debug('response', response);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${downloadType}-${reference}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', `There was an error downloading the ${this.typeDescription}, please try again later`);
      } finally {
        this.downloading.pop(reference);
      }
    },
    async refresh() {
      this.isLoading = true;
      this.dataItems = await this.LOAD_accountsReceivable(this.arType);
      this.isLoading = false;
    },
  },
  async mounted() {
    this.$root.$on('search', ({ text, type }) => {
      if (type === 'Documents') {
        this.search = text;
      }
    });
    await this.refresh();
  },
  destroyed() {
    this.$root.$off('search');
  },
};
</script>
<style scoped>
.mobile-search {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 5px !important;
}
</style>
